<template>
  <div class="Properties" v-if="selectedItem">
    <NavBar />
    <div class="container">
      <div class="item-view">
        <div class="notification" v-if="showNotification">
          <p>
            Your request has been sent successfully. We will get back to you
            very soon.
          </p>
          <i class="fa-solid fa-times" @click="showNotification = false"></i>
        </div>
        <div class="item-header">
          <h2>
            {{ selectedItem ? selectedItem.title : "Property not found" }}
          </h2>
          <p>
            <i class="fa-solid fa-location-dot"></i>
            <span>{{ selectedItem.location.name }}</span>
          </p>
        </div>
        <div class="images">
          <div class="main-image image-left">
            <img
              :src="$upload(selectedItem.image)"
              alt="main-image"
              @click="
                viewImages = true;
                selectedImage = selectedItem.image;
              "
            />
            <div class="bottom-left">
              <button class="my-btn wd cw bp" @click="viewImages = true">
                View all photos
              </button>
            </div>
          </div>
          <div class="sub-images image-right">
            <div
              class="sub-image"
              v-for="image in smallImages"
              :key="image"
              @click="
                viewImages = true;
                selectedImage = image;
              "
            >
              <img :src="$upload(image)" alt="sub-image" />
            </div>
            <div
              class="more-btn"
              v-if="selectedItem.images.length > 1"
              :style="
                'background: url(\'' +
                $upload(selectedItem.images[selectedItem.images.length - 1]) +
                '\') no-repeat center center/cover'
              "
            >
              <button @click="viewImages = true">
                View more
                <i class="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="item-details">
          <div class="left-details">
            <div
              class="features"
              v-if="
                selectedItem.category == 'House' ||
                selectedItem.category == 'Apartment'
              "
            >
              <div class="feature-item">
                <h3>Sitting rooms</h3>
                <p>
                  <i class="fa-solid fa-couch"></i>
                  {{ selectedItem.features.sitting_rooms }}
                </p>
              </div>
              <!-- bedrooms -->
              <div class="feature-item">
                <h3>Bedrooms</h3>
                <p>
                  <i class="fa-solid fa-bed"></i>

                  {{ selectedItem.features.bedrooms }}
                </p>
              </div>
              <!-- bathrooms -->
              <div class="feature-item">
                <h3>Bathrooms</h3>
                <p>
                  <i class="fa-solid fa-bath"></i>
                  {{ selectedItem.features.bathrooms }}
                </p>
              </div>
              <!-- Kitchen -->
              <div class="feature-item">
                <h3>Kitchen</h3>
                <p>
                  <i class="fa-solid fa-utensils"></i>
                  {{ selectedItem.features.kitchen }}
                </p>
              </div>
              <!-- Parking -->
              <div class="feature-item">
                <h3>Parking</h3>
                <p>
                  <i class="fa-solid fa-car"></i>
                  {{ selectedItem.features.parking }}
                </p>
              </div>
              <!-- Furnished -->
              <div class="feature-item">
                <h3>Furnished</h3>
                <p v-if="selectedItem.status == 'Furnished'">
                  <i class="fa-solid fa-check"></i>
                  Yes
                </p>
                <p v-else>
                  <i class="fa-solid fa-times"></i>
                  No
                </p>
              </div>
              <!-- Parking -->
              <div class="feature-item" v-if="selectedItem.features.garden">
                <h3>Garden</h3>
                <p>
                  <i class="fa-solid fa-tree"></i>
                  {{ selectedItem.features.garden }}
                </p>
              </div>
            </div>
            <div class="description" v-if="selectedItem.size">
              <h3>Size</h3>
              <div>
                <p>
                  {{ selectedItem.size }}
                </p>
              </div>
            </div>
            <div class="description" v-if="selectedItem.description">
              <h3>Description</h3>
              <div>
                <p v-html="selectedItem.description"></p>
              </div>
            </div>
            <div class="description" v-if="selectedItem.otherInfo">
              <h3>Other Info</h3>
              <div>
                <p>
                  {{ selectedItem.otherInfo }}
                </p>
              </div>
            </div>
            <div class="map">
              <h3>Map</h3>
              <div>
                <iframe
                  :src="
                    'https://www.google.com/maps?q=' +
                    selectedItem.location.address +
                    '&output=embed'
                  "
                ></iframe>
              </div>
            </div>
          </div>
          <div class="right-details">
            <div class="top-section">
              <label for="">Price</label>
              <h2>
                {{ $comma(selectedItem.price) }} {{ selectedItem.currency }}
                <span
                  v-if="
                    (selectedItem.category == 'House' ||
                      selectedItem.category == 'Apartment') &&
                    selectedItem.type == 'Rent'
                  "
                  >/Month</span
                >
              </h2>
              <button class="my-btn wd cw bp">
                Get more details <i class="fa-solid fa-arrow-right"></i>
              </button>
            </div>
            <div class="bottom-section">
              <h3>Request a home tour</h3>
              <form action="" @submit.prevent="sendRequest()">
                <div class="form-group">
                  <label for="name">Full Name</label>
                  <input
                    type="text"
                    id="name"
                    placeholder="Enter your name"
                    v-model="newRequest.full_name"
                  />
                </div>
                <div class="form-group">
                  <label for="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    placeholder="Enter your email"
                    v-model="newRequest.email"
                  />
                </div>
                <div class="form-group">
                  <label for="phone">Phone</label>
                  <input
                    type="tel"
                    id="phone"
                    placeholder="Enter your phone"
                    v-model="newRequest.phone"
                  />
                </div>
                <div class="form-group">
                  <label for="address">Address</label>
                  <input
                    type="text"
                    id="address"
                    placeholder="Enter your address"
                    v-model="newRequest.address"
                  />
                </div>
                <div class="form-group">
                  <label for="message">Date</label>
                  <input
                    type="date"
                    id="message"
                    placeholder="Enter your date"
                    v-model="newRequest.date"
                  />
                </div>
                <div class="form-group">
                  <label for="message">Time</label>
                  <input
                    type="time"
                    id="message"
                    placeholder="Enter your time"
                    v-model="newRequest.time"
                  />
                </div>
                <div class="form-group">
                  <label for="message">Message</label>
                  <textarea
                    name="message"
                    id="message"
                    cols="30"
                    rows="10"
                    placeholder="Any message"
                    v-model="newRequest.message"
                  ></textarea>
                </div>
                <button class="my-btn wd cw bp">Request a tour</button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="filtered-area" v-if="relateds.length">
        <div class="category-list">
          <div class="tab-header">
            <h2>Related Properties</h2>
          </div>
        </div>
        <div class="items-listing">
          <PropertyCard v-for="item in relateds" :key="item.id" :item="item" />
        </div>
      </div>
      <ImagesViewer
        :images="selectedItem.images"
        v-if="viewImages"
        @close="
          viewImages = false;
          selectedImage = null;
        "
        :iTitle="selectedItem.title + ' Images'"
        :activeImage="selectedImage"
      />
    </div>
    <Footer />
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
export default {
  components: {},
  data() {
    return {
      relateds: [],
      selectedId: null,
      selectedItem: null,
      viewImages: false,
      newRequest: {
        full_name: "",
        email: "",
        phone: "",
        date: "",
        time: "",
        address: "",
        message: "",
      },
      showNotification: false,
      selectedImage: null,
      smallImages: [],
    };
  },
  methods: {
    getItems() {
      this.$startLoader();
      this.$store
        .dispatch("GET_PROPERTY", {
          token: this.$getAdminToken(),
          data: {
            property_id: this.selectedId,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.selectedItem = res.data.property;
              this.relateds = this.selectedItem.related;
              let smallImages = [...this.selectedItem.images];
              this.smallImages = smallImages.filter((item, index) => index < 3);
              this.selectedItem.images.unshift(this.selectedItem.image);
              this.$stopLoader();
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
    sendRequest() {
      if (
        !this.newRequest.full_name ||
        !this.newRequest.email ||
        !this.newRequest.phone ||
        !this.newRequest.address ||
        !this.newRequest.date
      ) {
        toast.error("All fields are required");
        return;
      }
      this.$startLoader();
      this.$store
        .dispatch("CREATE_REQUEST", {
          token: this.$getAdminToken(),
          data: {
            property_id: this.selectedId,
            ...this.newRequest,
          },
        })
        .then((res) => {
          if (res) {
            if (res.status === 200) {
              this.showNotification = true;
              this.$stopLoader();
              this.newRequest = {
                name: "",
                email: "",
                phone: "",
                date: "",
                time: "",
                address: "",
                message: "",
              };
              window.scrollTo(0, 0);
            } else {
              toast.error(res.data.message);
              this.$stopLoader();
            }
          }
        })
        .catch(() => {
          toast.error("Something went wrong, please try again later.");
          this.$stopLoader();
        });
    },
  },
  mounted() {
    this.selectedId = this.$route.params.id;
    this.getItems();
    window.scrollTo(0, 0);
  },
  computed: {},
  watch: {
    $route(to, from) {
      this.selectedId = to.params.id;
      this.getItems();
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
$primary_color: #1d2088;
$secondary_color: #1eb9ee;
.Properties {
  .filtered-area {
    margin-bottom: 3rem;
  }
  .item-view {
    margin-top: 150px;
    .item-header {
      h2 {
        font-size: 2.5rem;
        color: #000;
      }
      p {
        margin-bottom: 1rem;
        font-size: 1.8rem;
        color: $secondary_color;
        font-weight: 700;
        i {
          color: $secondary_color;
          margin-right: 0.5rem;
        }
      }
    }
    .images {
      display: flex;
      flex-direction: row;
      gap: 0.51rem;
      @media (max-width: 1200px) {
        flex-direction: column;
      }
      .main-image {
        width: 750px;
        height: 500px;
        position: relative;
        overflow: hidden;
        border-radius: 10px;
        @media (max-width: 1200px) {
          width: 100%;
        }
        img {
          height: 100%;
          width: 100%;
          cursor: pointer;
          object-fit: cover;
        }
        .bottom-left {
          position: absolute;
          bottom: 2rem;
          left: 2rem;
          button {
            padding: 10px 40px;
          }
        }
      }
      .sub-images {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0.5rem;
        width: calc(100% - 750px);
        @media (max-width: 1200px) {
          width: 100%;
          grid-template-columns: repeat(4, 1fr);
        }
        @media (max-width: 1200px) {
          width: 100%;
          grid-template-columns: repeat(4, 1fr);
        }
        @media (max-width: 800px) {
          width: 100%;
          grid-template-columns: repeat(2, 1fr);
        }
        .sub-image {
          width: 100%;
          height: 245px;
          overflow: hidden;
          border-radius: 10px;
          @media (max-width: 800px) {
            height: 180px;
          }
        }
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          cursor: pointer;
        }
      }
      .more-btn {
        position: relative;
        width: 100%;
        height: 250px;
        border-radius: 10px;
        overflow: hidden;
        @media (max-width: 800px) {
          height: 180px;
        }
        button {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          background: #000000b7;
          height: 100%;
          width: 100%;
          color: white;
          font-size: 1.2rem;
        }
      }
    }
    .item-details {
      display: flex;
      align-items: flex-start;
      column-gap: 1rem;
      margin-top: 2rem;
      @media (max-width: 1200px) {
        flex-direction: column;
      }
      .left-details {
        width: 70%;
        @media (max-width: 1200px) {
          width: 100%;
        }
        .features {
          display: flex;
          justify-content: space-between;
          column-gap: 1rem;
          border: 1px solid #ccc;
          border-radius: 10px;
          padding: 2rem;
          flex-wrap: wrap;
          .feature-item {
            min-width: 100px;
            h3 {
              font-size: 1rem;
              color: #3d3d3d;
              font-weight: 500;
              margin-bottom: 0.75rem;
            }
            p {
              font-size: 1rem;
              color: #000;
              font-weight: 700;
              i {
                color: $secondary_color;
                margin-right: 0.5rem;
                font-size: 1.1rem;
              }
            }
          }
        }
        .description {
          margin-top: 2rem;
          h3 {
            font-size: 2rem;
            color: #000;
            font-weight: 700;
            margin-bottom: 1.5rem;
          }
          p {
            font-size: 1rem;
            color: #3d3d3d;
            margin-bottom: 2rem;
            line-height: 0.5;
          }
        }
        .map {
          margin-top: 2rem;
          h3 {
            font-size: 2rem;
            margin-bottom: 1.5rem;
            color: #000;
            font-weight: 700;
          }
          iframe {
            width: 100%;
            height: 450px;
            border: 0;
            border-radius: 10px;
          }
        }
      }
      .right-details {
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 2rem;
        width: 30%;
        @media (max-width: 1200px) {
          width: 100%;
        }
        .top-section {
          label {
            font-size: 1rem;
            color: #3d3d3d;
            font-weight: 500;
          }
          h2 {
            font-size: 2rem;
            color: #000;
            font-weight: 700;
            span {
              font-size: 0.9rem;
              color: #333;
              font-weight: 400;
            }
          }
          button {
            margin-top: 1rem;
            padding: 10px 40px;
            width: 100%;
          }
        }
        .bottom-section {
          margin-top: 2rem;
          h3 {
            font-size: 1.2rem;
            color: #333;
            font-weight: 700;
            margin-bottom: 1rem;
          }
          form {
            .form-group {
              margin-bottom: 1rem;
              label {
                font-size: 1rem;
                color: #333;
                font-weight: 400;
              }
              input,
              textarea {
                width: 100%;
                padding: 10px;
                border: 1px solid #ccc;
                border-radius: 5px;
                font-size: 0.9rem;
                color: #333;
                margin-top: 0.5rem;
                outline: none;
              }
              textarea {
                height: 100px;
              }
            }
            button {
              padding: 10px 40px;
              background: $secondary_color;
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.notification {
  position: relative;
  padding: 20px;
  background: #b3e9be;
  border-radius: 10px;
  margin-top: 2rem;
  p {
    font-size: 1.2rem;
    color: #10422b;
    font-weight: 500;
  }
  i {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    font-size: 1.5rem;
    color: #10422b;
    cursor: pointer;
  }
}
</style>
